import React from 'react';
import Layout from "../components/layout";
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
//import {Link} from "gatsby-theme-material-ui";
import {red} from '@material-ui/core/colors';
import Seo from "../components/seo";
import {useIntl, navigate, Link, FormattedHTMLMessage, FormattedMessage} from "gatsby-plugin-intl"




const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    actionItem: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
}));
const Index = () => {
    const intl = useIntl();
    const classes = useStyles();
    
    
    
    
    return (
        <Layout>
            <Seo title={intl.formatMessage({ id: "titulo" })}

                 description={intl.formatMessage({id: "index.description"})}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={"body1"}>
                           {intl.formatMessage({ id: "index.covid" })}
                    </Typography> <br />
                    <Typography>
                        <FormattedMessage
                            id="index.masInfo"
                            values={{
                                a: text => <a href={"https://www.acguanacaste.ac.cr/turismo"} target={"_blank"} rel={"noreferrer"}>{text}</a>,
                            }}
                        />
                        {intl.formatMessage({id: "index.contacto1"})} <a href="mailto:ecoturismo@acguanacaste.ac.cr">ecoturismo@acguanacaste.ac.cr</a>, {intl.formatMessage({id: "telefono"})}: <a href="tel:+50626665051">2666-5051</a>, WhatsApp: <a href="https://api.whatsapp.com/send?phone=50688869510">88869510</a>
                    </Typography>

                </Grid>



                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            window.open("https://serviciosenlinea.sinac.go.cr/", "_blank")
                        }}>
                            <CardMedia
                                component="img"
                                alt="Reservaciones de ingreso al Pailas, Rincón de la Vieja de ACG"
                                height="200"
                                image="/images/pailas-cover.jpg"
                                title="Pailas, Rincón de la Vieja de ACG"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "SP"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage
                                        id="index.rv"
                                        values={{
                                            a: text => <a href={"https://serviciosenlinea.sinac.go.cr/"}>{text}</a>,
                                        }}
                                    />
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <a href="https://serviciosenlinea.sinac.go.cr/" target={"_blank"} rel={"noreferrer"}  color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Comprar"})}
                            </a>
                        </CardActions>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            navigate("/santa-rosa")
                        }}>
                            <CardMedia
                                component="img"
                                alt="Reservaciones de ingreso al Sector Santa Rosa de ACG "
                                height="200"
                                image="/images/santa-rosa-cover.jpg"
                                title="Sector Santa Rosa, ACG"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "SR"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {intl.formatMessage({id: "index.srinfo"})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
            
                        <CardActions>
                            <Link to={"/santa-rosa"} color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Reservar"})}
                            </Link>
            
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            navigate("/naranjo")
                        }}>
                            <CardMedia
                                component="img"
                                alt="Reservaciones de ingreso al Sector Playa Naranjo, Parque Nacional Santa Rosa de ACG "
                                height="200"
                                image="/images/naranjo.jpg"
                                title="Sector Playa Naranjo, P.N. Santa Rosa, ACG"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "SPN"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {intl.formatMessage({id: "index.naranjo"})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>

                        <CardActions>
                            <Link to={"/naranjo"} color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Reservar"})}
                            </Link>

                        </CardActions>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            navigate("/marino")
                        }}>
                            <CardMedia
                                component="img"
                                alt="Reservaciones de ingreso al Sector Marino de ACG"
                                height="200"
                                image="/images/marino-cover2.jpg"
                                title="Sector Marino, ACG"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "SM"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {intl.formatMessage({id: "index.sminfo"})}
                                    
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        
                        <CardActions>
                            <Link to={"/marino"} color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Reservar"})}
                            </Link>
                        
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            window.open("https://serviciosenlinea.sinac.go.cr/", "_blank")
                        }}>
                            <CardMedia
                                component="img"
                                alt="Reservaciones de ingreso al Sector Santa María, Rincón de la Vieja"
                                height="200"
                                image="/images/santa-maria-cover.jpg"
                                title="Sector Santa María, ACG"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "SStMa"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <FormattedMessage
                                        id="index.rv"
                                        values={{
                                            a: text => <a href={"https://serviciosenlinea.sinac.go.cr/"}>{text}</a>,
                                        }}
                                    />

                                </Typography>
                            </CardContent>
                        </CardActionArea>

                        <CardActions>
                            <a href="https://serviciosenlinea.sinac.go.cr/" target={"_blank"} rel={"noreferrer"}  color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Comprar"})}
                            </a>

                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            navigate("/horizontes")
                        }}>
                            <CardMedia
                                component="img"
                                alt={intl.formatMessage({id: "index.horizontes1"})}
                                height="200"
                                image="/images/horizontes-cover.jpg"
                                title={intl.formatMessage({id: "index.horizontes1"})}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "EEFH"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {intl.formatMessage({id: "index.hinfo"})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
            
                        <CardActions>
                            <Link to={"/horizontes"} color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Reservar"})}
                            </Link>
            
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            navigate("/murcielago")
                        }}>
                            <CardMedia
                                component="img"
                                alt={intl.formatMessage({id: "index.murcielago"})}
                                height="200"
                                image="/images/murcielago.webp"
                                title={intl.formatMessage({id: "index.murcielago"})}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "SMu"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {intl.formatMessage({id: "index.murcielago"})}
                                </Typography>
                            </CardContent>
                        </CardActionArea>

                        <CardActions>
                            <Link to={"/murcielago"} color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Reservar"})}
                            </Link>

                        </CardActions>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            navigate("/sr-escuelas")
                        }}>
                            <CardMedia
                                component="img"
                                alt="Reservaciones para Centros Educativos, Sector Santa Rosa"
                                height="200"
                                image="/images/sr-escuelas.jpg"
                                title="Reservaciones para Centros Educativos, Sector Santa Rosa"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "SREsc"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {intl.formatMessage({id: "index.escuelas"})}

                                </Typography>
                            </CardContent>
                        </CardActionArea>

                        <CardActions>
                            <Link to={"/sr-escuelas"} color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Reservar"})}
                            </Link>

                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => {
                            navigate("/junquillal")
                        }}>
                            <CardMedia
                                component="img"
                                alt="Reservaciones de ingreso al Sector Junquillal"
                                height="200"
                                image="/images/junquillal-cover.jpg"
                                title="Sector junquillal, ACG"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {intl.formatMessage({id: "SJ"})}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {intl.formatMessage({id: "index.jun"})}

                                </Typography>
                            </CardContent>
                        </CardActionArea>

                        <CardActions>
                            <Link to={"/junquillal"} color="primary" className={classes.actionItem}>
                                {intl.formatMessage({id: "Reservar"})}
                            </Link>

                        </CardActions>
                    </Card>
                </Grid>


                
                
 
            </Grid>
        </Layout>
    );
}

export default Index;